// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-video-js": () => import("./../src/pages/music-video.js" /* webpackChunkName: "component---src-pages-music-video-js" */),
  "component---src-pages-narrative-js": () => import("./../src/pages/narrative.js" /* webpackChunkName: "component---src-pages-narrative-js" */),
  "component---src-pages-nonfiction-video-js": () => import("./../src/pages/nonfiction-video.js" /* webpackChunkName: "component---src-pages-nonfiction-video-js" */),
  "component---src-pages-reel-js": () => import("./../src/pages/reel.js" /* webpackChunkName: "component---src-pages-reel-js" */),
  "component---src-pages-social-video-js": () => import("./../src/pages/social-video.js" /* webpackChunkName: "component---src-pages-social-video-js" */),
  "component---src-pages-writing-js": () => import("./../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

